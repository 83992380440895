import { useState, useRef, useEffect } from "react";
import img1 from "../../assets/hair-styles/hs1.jpg";
import img2 from "../../assets/hair-styles/hs2.jpg";
import img3 from "../../assets/hair-styles/hs3.jpg";
import img4 from "../../assets/hair-styles/hs4.jpg";
import img5 from "../../assets/hair-styles/hs5.jpg";
import img6 from "../../assets/hair-styles/hs6.jpg";
import img7 from "../../assets/hair-styles/hs7.jpg";
import img8 from "../../assets/hair-styles/hs8.jpg";
import img9 from "../../assets/hair-styles/hs9.jpg";
import img10 from "../../assets/hair-styles/hs10.jpg";
import img11 from "../../assets/hair-styles/hs11.jpg";
import img12 from "../../assets/hair-styles/hs12.jpg";
import img13 from "../../assets/hair-styles/hs13.jpg";
import img14 from "../../assets/hair-styles/hs14.jpg";
import img15 from "../../assets/hair-styles/hs15.jpg";
import img16 from "../../assets/hair-styles/hs16.jpg";
import img17 from "../../assets/hair-styles/hs17.jpg";
import img18 from "../../assets/hair-styles/hs18.jpg";
import img19 from "../../assets/hair-styles/hs19.jpg";
import img20 from "../../assets/hair-styles/hs20.jpg";
import img21 from "../../assets/hair-styles/hs21.jpg";


import "./background.css";
const Background = () => {
  const images1 = [img1, img8, img15 ];
  const images2 = [img16, img2, img9 ];
  const images3 = [img3, img10, img17];
  const images4 = [img4, img11, img18];
  const images5 = [img5, img12, img19];
  const images6 = [img6, img13, img20];
  const images7 = [img7, img14, img21];
  
  const [currentSlide, setCurrentSlide] = useState(0);
  // useRef does not cause a re-render
  let sliderInterval = useRef();
  let switchImages = () => {
    if (currentSlide < images1.length - 1) {
      setCurrentSlide(currentSlide + 1);
    } else {
      setCurrentSlide(0);
    }
  };
  useEffect(() => {
    sliderInterval = setInterval(() => {
      switchImages();
    }, 5000);
    return () => {
      clearInterval(sliderInterval);
    };
  });
  return (
    <div className="mainanim" id="mainanim">
    <div className="anim1">

    <div className="imgWrapper">
      {images1.map((img, index) => {
        return (
          <img
            src={img}
            className={
              index === currentSlide ? "imageActive homeImage" : "image"
            }
          />
        );
      })}
    </div>

    </div>
    <div className="anim2">
    <div className="imgWrapper">
      {images2.map((img, index) => {
        return (
          <img
            src={img}
            className={
              index === currentSlide ? "imageActive homeImage" : "image"
            }
          />
        );
      })}
    </div>

</div>
 <div className="anim3">
 <div className="imgWrapper">
      {images3.map((img, index) => {
        return (
          <img
            src={img}
            className={
              index === currentSlide ? "imageActive homeImage" : "image"
            }
          />
        );
      })}
    </div>

</div>
<div className="anim4">
 <div className="imgWrapper">
      {images4.map((img, index) => {
        return (
          <img
            src={img}
            className={
              index === currentSlide ? "imageActive homeImage" : "image"
            }
          />
        );
      })}
    </div>

</div>

<div className="anim5">
 <div className="imgWrapper">
      {images5.map((img, index) => {
        return (
          <img
            src={img}
            className={
              index === currentSlide ? "imageActive homeImage" : "image"
            }
          />
        );
      })}
    </div>

</div>

<div className="anim6">
 <div className="imgWrapper">
      {images6.map((img, index) => {
        return (
          <img
            src={img}
            className={
              index === currentSlide ? "imageActive homeImage" : "image"
            }
          />
        );
      })}
    </div>

</div>

<div className="anim7">
 <div className="imgWrapper">
      {images7.map((img, index) => {
        return (
          <img
            src={img}
            className={
              index === currentSlide ? "imageActive homeImage" : "image"
            }
          />
        );
      })}
    </div>

</div>

    
    </div>
  );
};

export default Background;