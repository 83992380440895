import { useState, useRef, useEffect } from "react";

import img22 from "../../assets/hair-styles/hs22.jpg";
import img23 from "../../assets/hair-styles/hs23.jpg";
import img24 from "../../assets/hair-styles/hs24.jpg";
import img25 from "../../assets/hair-styles/hs25.jpg";
import img26 from "../../assets/hair-styles/hs26.jpg";
import img27 from "../../assets/hair-styles/hs27.jpg";
import img28 from "../../assets/hair-styles/hs28.jpg";
import img29 from "../../assets/hair-styles/hs29.jpg";
import img30 from "../../assets/hair-styles/hs30.jpg";
import img31 from "../../assets/hair-styles/hs31.jpg";
import img32 from "../../assets/hair-styles/hs32.jpg";
import img33 from "../../assets/hair-styles/hs33.jpg";
import img34 from "../../assets/hair-styles/hs34.jpg";
import img35 from "../../assets/hair-styles/hs35.jpg";
import img36 from "../../assets/hair-styles/hs36.jpg";
import img37 from "../../assets/hair-styles/hs37.jpg";
import img38 from "../../assets/hair-styles/hs38.jpg";
import img39 from "../../assets/hair-styles/hs39.jpg";
import img40 from "../../assets/hair-styles/hs40.jpg";
import img41 from "../../assets/hair-styles/hs41.jpg";
import img42 from "../../assets/hair-styles/hs42.jpg";
import img43 from "../../assets/hair-styles/hs43.jpg";
import img44 from "../../assets/hair-styles/hs44.jpg";


import "./background.css";
const Background = () => {
  const images1 = [img22, img29, img36 ];
  const images2 = [img23, img30, img37 ];
  const images3 = [img24, img31, img38];
  const images4 = [img25, img32, img39];
  const images5 = [img26, img33, img40];
  const images6 = [img27, img34, img41];
  const images7 = [img28, img35, img42];
  
  const [currentSlide, setCurrentSlide] = useState(0);
  // useRef does not cause a re-render
  let sliderInterval = useRef();
  let switchImages = () => {
    if (currentSlide < images1.length - 1) {
      setCurrentSlide(currentSlide + 1);
    } else {
      setCurrentSlide(0);
    }
  };
  useEffect(() => {
    sliderInterval = setInterval(() => {
      switchImages();
    }, 5000);
    return () => {
      clearInterval(sliderInterval);
    };
  });
  return (
    <div className="mainanim" id="mainanim">
    <div className="anim1">

    <div className="imgWrapper">
      {images1.map((img, index) => {
        return (
          <img
            src={img}
            className={
              index === currentSlide ? "imageActive homeImage" : "image"
            }
          />
        );
      })}
    </div>

    </div>
    <div className="anim2">
    <div className="imgWrapper">
      {images2.map((img, index) => {
        return (
          <img
            src={img}
            className={
              index === currentSlide ? "imageActive homeImage" : "image"
            }
          />
        );
      })}
    </div>

</div>
 <div className="anim3">
 <div className="imgWrapper">
      {images3.map((img, index) => {
        return (
          <img
            src={img}
            className={
              index === currentSlide ? "imageActive homeImage" : "image"
            }
          />
        );
      })}
    </div>

</div>
<div className="anim4">
 <div className="imgWrapper">
      {images4.map((img, index) => {
        return (
          <img
            src={img}
            className={
              index === currentSlide ? "imageActive homeImage" : "image"
            }
          />
        );
      })}
    </div>

</div>

<div className="anim5">
 <div className="imgWrapper">
      {images5.map((img, index) => {
        return (
          <img
            src={img}
            className={
              index === currentSlide ? "imageActive homeImage" : "image"
            }
          />
        );
      })}
    </div>

</div>

<div className="anim6">
 <div className="imgWrapper">
      {images6.map((img, index) => {
        return (
          <img
            src={img}
            className={
              index === currentSlide ? "imageActive homeImage" : "image"
            }
          />
        );
      })}
    </div>

</div>

<div className="anim7">
 <div className="imgWrapper">
      {images7.map((img, index) => {
        return (
          <img
            src={img}
            className={
              index === currentSlide ? "imageActive homeImage" : "image"
            }
          />
        );
      })}
    </div>

</div>

    
    </div>
  );
};

export default Background;