import image1 from "../../src/assets/owners/owner3.jpg";
import image2 from "../../src/assets/owners/owner2.jpg";
import image3 from "../../src/assets/owners/owner1.jpg";

export const testimonialsData = [
  {
    image: image1,
    review:
      "At the රූ සැලෝන්, we have confidence in excellence with a heart. We have made a salon that offers the most noteworthy quality hair benefits in a setting that is more advantageous for the earth, our visitors, and our staff. ",
    name: 'Suraj Mayantha Perera',
    status : 'ENTREPRENEUR'
  },
  {
    image: image2,
    review: 'රූ සලෝන්,Our hair is a type of articulation and style, and like a bit of craftsmanship, our hair is a canvas. We are propelled by the lovely magnificence of hair and the dynamic design industry that encompasses us. We are focused on making the ideal style for all of our customers.',
    name: 'Amal Dushmantha Karunaratne',
    status: 'COACH'
  },
  {
    image : image3,
    review:'A honor-winning kalawana hair and magnificence salon, ‘රූ සැලෝන්’ offers a safe house of quiet and liberal extravagance in the occupied kalawana avenues. It’s all around regarded and believed notoriety has been cultivated since first opening in the mid year of 2018.',
    name: 'Kevin Alvis',
    status: "CUSTOMER"
  }
];
