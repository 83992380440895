import React from 'react';
import './Reasons.css';
import Image1 from '../../assets/reason/reason6.jpg'
import Image2 from '../../assets/reason/reason2.jpg';
import Image3 from '../../assets/reason/reason3.jpg';
import Image4 from '../../assets/reason/reason4.jpg';
import tick from '../../assets/tick.png';
import nb from '../../assets/partner/partner1.jpg';
import adidas from '../../assets/partner/partner2.jpg';
import nike from '../../assets/partner/partner3.jpg';
 
const Reasons=()=>{
    return(
        <div className='reasons' id='reasons'>
        <div className='left-r'>
            <img src={Image1} alt=''/>
            <img src={Image2} alt=''/>
            <img src={Image3} alt=''/>
            <img src={Image4} alt=''/>
        </div>
        <div className='right-r'>
           <span>some reasons</span>
           <div>
            <span className='stroke-text'>why </span>
            <span>choose us?</span>
           </div>
           <div className='details-r'>
            <div>
                <img src={tick} alt='' /> 
            <span>Individual Care</span>
            </div>
            <div>
                <img src={tick} alt='' /> 
            <span>Experience</span>
            </div>
            <div>
                <img src={tick} alt='' /> 
            <span>Advanced Technology</span>
            </div>
            <div>
                <img src={tick} alt='' /> 
            <span>Free Consultations</span>
            </div>
            <div>
                <img src={tick} alt='' /> 
            <span>Fixed Prices</span>
            </div>
            <div>
                <img src={tick} alt='' /> 
            <span>Loyalty Offers</span>
            </div>
            <div>
                <img src={tick} alt='' /> 
            <span>Fixed Prices</span>
            </div>
            <div>
                <img src={tick} alt='' /> 
            <span>Customer Service</span>
            </div>
           </div>
           <span style={{
            color:"grey",
           fontWeight:"ormal",
           }}>
           OUR PARTNERS</span>
           <div className='partners'>
           <img src={nb} alt="" />
           <img src={adidas} alt="" />
           <img src={nike} alt="" />

           </div>
        </div>
        </div>
    )
}
export default Reasons