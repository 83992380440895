import './App.css';
import Footer from './components/footer/footer';
import Hero from "./components/hero";
import Join from './components/join/join';
import Plans from './components/plans/plans';
import Programs from './components/programs/Programs';
import Reasons from './components/Reasons/Reasons';
import Testimonials from './components/testimonials/testimonials';
import Background from './components/Background/background';
import Background1 from './components/Background/background1'

function App() {
  return (
    <div className="App">
       <Hero/>
       <Background/>
       <Background1/>
       <Programs/>
       <Reasons/>
       <Plans/>
        <Testimonials/>
        <Join/>
      
        <Footer/>

    </div>
  );
}

export default App;
