import { useState, useRef, useEffect } from "react";

import img22 from "../../assets/logo/logo.png";


import "./background.css";
const Background = () => {
  const images1 = [img22, img22, img22 ];
  
  
  const [currentSlide, setCurrentSlide] = useState(0);
  // useRef does not cause a re-render
  let sliderInterval = useRef();
  let switchImages = () => {
    if (currentSlide < images1.length - 1) {
      setCurrentSlide(currentSlide + 1);
    } else {
      setCurrentSlide(0);
    }
  };
  useEffect(() => {
    sliderInterval = setInterval(() => {
      switchImages();
    }, 5000);
    return () => {
      clearInterval(sliderInterval);
    };
  });
  return (
    <div className="logoimage" id="mainanim">
    <div className="anim1">

    <div className="imgWrapper">
      {images1.map((img, index) => {
        return (
          <img
            src={img}
            className={
              index === currentSlide ? "imageActive1 homeImage" : "image"
            }
          />
        );
      })}
    </div>

    </div>
    </div>
    
  );
};

export default Background;